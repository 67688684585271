<template>
    <div class="bg-white w-full">
        <AlertBanner :page="nursePage" :slug="nurseSlug"/>
    </div>
</template>

<script>
import AlertBanner from '../../../views/components/shop/AlertBanners.vue';


export default {
    components: {
        AlertBanner
    },
    data: () => ({
        nurseSlug: "",
        nursePage: ""
    }),
    methods: {
        getPage(pageName) {
            switch (pageName) {
                case "NurseOrderCenter":
                    this.nursePage = "homepage"
                    break;
                case "NurseOrderCenterCategoryPage":
                    this.nursePage = "category"
                    break;
                // case "NurseOrderCenterCategory_Page":
                //     this.nursePage = "category"
                //     break;
                case "NurseOrderCenterBrandPage":
                    this.nursePage = "brand"
                    break;
                case "NurseProductDetail":
                    this.nursePage = "productDetail"
                    break;
                case "NurseProductCheckout":
                    this.nursePage = "checkout"
                    break;
                default:
                    break;
            }
        }
    },
    watch: {
        "$route.name": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.getPage(this.$route.name);
                }
            },
            immediate: true
        },
        "$route.params.slug": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.nurseSlug = newVal 
                }
            }
        }
    },
    created() {
        this.nurseSlug = this.$route.params.slug
        this.getPage(this.$route.name);
    }
}

</script>
